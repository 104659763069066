.home {
  background: #0E0C1F;

  .home-content {
    height: 100vh;
    width: 60vw;
    overflow: auto;

    .home-content-wrapper {
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: 7rem;
      justify-content: center;

      .logo {
        margin-bottom: 24px;
      }

      h1 {
        font-weight: 700;
        font-size: 3em;
        margin-bottom: 48px;
        line-height: 1.2;

        span {
          color: #897DF1;
        }
      }

      .form {
        display: flex;
        gap: 32px;
        align-items: flex-end;

        section {
          display: flex;
          flex-direction: column;
          gap: 8px;
          flex: 2;

          input, select {
            height: 60px;
            border: none;
            color: black;
            outline: none;
            padding: 0 16px;
            font-family: 'Montserrat', sans-serif;
          }
        }

        a {
          flex: 1;
          width: 100%;
          display: flex;
          height: 60px;
          padding: 1rem;
          background: #897DF1;
          justify-content: center;
          align-items: center;
          font-weight: 700;
          font-size: 1.2em;
        }
      }
    }
  }

  .demo {
    position: fixed;
    top: 0;
    right: 0;
    width: 40vw;
    height: 100%;
    display: flex;
    background: #090812;
    flex-direction: column;

    .demo-chat {
      height: 100%;
    }
  }

  @media screen and (max-width: 1280px) {
    .home-content {
      .home-content-wrapper {
        padding: 4rem;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    .home-content {
      .home-content-wrapper {
        .form {
          flex-direction: column;
          justify-content: flex-start;
          align-items: stretch;

          a {
            flex: 1 0 auto;
          }
        }
      }
    }
  }

  @media screen and (max-width: 720px) {
    .home-content {
      width: 100vw;

      .home-content-wrapper {
        height: auto;
        padding: 2rem;
      }
    }

    .demo {
      display: none;
    }
  }
}