@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@800&display=swap");

.theme-benandback {
  font-family: "Mulish", sans-serif;
  margin-bottom: 32px;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  display: flex;
  padding: 32px;

  @keyframes pop {
    0% {
      opacity: 0;
      left: 110%;
    }

    100% {
      opacity: 1;
      left: 0;
    }
  }

  .msg {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    animation: pop 1s cubic-bezier(1, 0, 0, 1) both;
    position: relative;
  }

  .name {
    display: inline-flex;
    height: 29px;
    padding: 0 12px;
    background: white;
    align-items: center;
    font-weight: 800;
    font-size: 10px;
    color: black;
    border: 1px solid black;
    margin-left: -1px;
  }

  .message {
    padding: 14px 12px;
    background: transparent;
    color: white;
    margin-top: 0px;
    line-height: 1.4;
    text-align: left;
    font-weight: 800;
    overflow-wrap: break-word;
    max-width: 100%;
    vertical-align: baseline;
    line-height: 1.4;
    border: 1px solid white;
    margin-top: -1px;
    font-size: 12px;

    img {
      width: 24px;
      height: auto;
      vertical-align: middle;
      margin: 0 4px;
    }
  }
}
