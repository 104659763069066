@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@700&display=swap');

.theme-will {
  padding: 24px;
  overflow: hidden;
  width: 100%;
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  font-size: 1.2em;
  align-items: flex-end;
  justify-content: flex-end;

  .will-message {
    .container-message-will {
      background: white;
      border: 5px solid black;
      padding: 32px 24px 24px 24px;
      border-radius: 24px;
      box-shadow: 0 12px 0 rgba(#1E0D61, .47);
      margin-bottom: 20px;
      margin-top: 32px;
      position: relative;
      text-align: right;
      display: inline-flex;
      width: auto;
      max-width: 100%;
      font-family: 'Rubik', sans-serif;
    }

    p {
      display: inline-flex;
      height: 44px;
      border: 3px solid black;
      padding: 0 8px;
      border-radius: 12px;
      align-items: center;
      position: absolute;
      top: -26px;
      right: 24px;
      color: black;
    }

    div {
      overflow-wrap: break-word;
      max-width: 100%;
      vertical-align: baseline;
      line-height: 1.4;
      color: black;

      img {
        width: 36px;
        height: auto;
        vertical-align: middle;
      }
    }
  }
}

@keyframes slideRight {
  0% {
    transform: translateX(110%);
  }

  100% {
    transform: translateX(0);
  }
}