@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700;900&display=swap');

.theme-willv2 {
  font-family: 'Source Sans Pro', sans-serif;
  margin-bottom: 32px;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  display: flex;
  padding: 32px;

  @keyframes pop {
    0% {
      opacity: 0;
      left: 110%;
    }
  
    100% {
      opacity: 1;
      left: 0;
    }
  }

  .msg {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    animation: pop 1s cubic-bezier(1, 0, 0, 1) both;
    position: relative;
  }

  .name {
    display: inline-flex;
    height: 36px;
    border-radius: 44px;
    padding: 0 16px;
    border: 1px solid rgba(white, .2);
    background: rgba(white, .15);
    align-items: center;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 1em;
    font-size: 12px;
  } 

  .message {
    padding: 16px;
    background: white;
    color: black;
    border-radius: 16px;
    margin-top: 8px;
    line-height: 1.4;
    text-align: right;
    font-weight: 600;
    overflow-wrap: break-word;
    max-width: 100%;
    vertical-align: baseline;
    line-height: 1.4;

    img {
      width: 24px;
      height: auto;
      vertical-align: middle;
      margin: 0 4px;
    }
  }
}